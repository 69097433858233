import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist'
import print from 'vue3-print-nb'
import './style.css'
import App from './App.vue'
import router from './utils/routes'
import {storeConfig} from './utils/store'
// @ts-ignore
import TimeLine from './components/TimeLine/index.js'
//先获取配置文件
fetch('/sysconfig.json')
.then(response => response.json())
.then(data => {
  const app= createApp(App)
  app.use(print)
  app.use(router)
  app.use(createPinia().use(piniaPluginPersist))
  app.use(TimeLine, { comName: 'TimeLine' })
  let storconfig = storeConfig()
  storconfig.setConfig('centerBaserurl', data.baseurlCenter)
  storconfig.setConfig('tenantBaseurl', data.baseurlTenant)
  if (window.location.href.includes('hdjp.hemikj.com')) storconfig.setConfig('domain', 'hdjp')
  app.mount('#app')
})
.catch(error => {
  console.error('读取JSON文件时发生错误:', error);
})
